import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function shop(){
  const sections = document.querySelectorAll(".afterbox")
  sections.forEach((el) => {
    gsap.fromTo(
      el,
      { 
        opacity: 0,
        y: 20,
        transform: 'scale(.88) rotateX(-20deg)',
      },
      {
        y: 0,
        opacity: 1,
        transform: 'scale(1) rotateX(0)',
        duration: 1,
        ease: Power2.easeInOut,
        scrollTrigger: {
          trigger: el,
          start: 'top bottom',
          end: 'top center',
          scrub: 2,
          //markers: true
        },
      }
    )

    gsap.fromTo(
      el.querySelector('.afterbox__clum__pic img'),
      { 
        scale: 1.2,
      },
      {
        scale: 1,
        duration: 1,
        ease: Power2.easeInOut,
        scrollTrigger: {
          trigger: el,
          start: 'top bottom',
          end: 'top center',
          scrub: 3,
          //markers: true
        },
      }
    )

    gsap.fromTo(
      el,
      { 
        y: 0,
        opacity: 1,
        transform: 'scale(1) rotateX(0)',
      },
      {
        y: -20,
        opacity: 0,
        transform: 'scale(.88) rotateX(20deg)',
        duration: 1,
        ease: Power2.easeInOut,
        scrollTrigger: {
          trigger: el,
          start: 'top top',
          end: 'bottom top',
          scrub: 2,
          //markers: true
        },
      }
    )
  })
};
