import Swiper from 'swiper/bundle';
import $ from 'jquery';
import ripples from 'jquery.ripples';
import * as imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function index(){
  const rippleEl = document.getElementById('ripples');
	if(rippleEl){
		imagesLoaded(rippleEl, function(){
			$('.ripples').ripples({
        interactive: false
			});
      efectRipples()
      setInterval(()=> {
        efectRipples()
      },8000)
		})
	}

  imagesLoaded('#gallery img', function(){
    const gallery = document.getElementById('gallery');
    if(gallery){
      new Swiper(gallery, {
        spaceBetween: 2,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        speed: 600,
        grabCursor: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      })
    }
  })

  let follower = document.getElementById('follower')
	let followItem = document.querySelectorAll(".clickable")
	if(follower){
		let
			fWidth = 80,
			delay = 8,
			mouseX = 0,
			mouseY = 0,
			posX = 0,
			posY = 0;

		TweenMax.to({}, .01, {
			repeat: -1,
			onRepeat: function() {
				posX += (mouseX - posX) / delay
				posY += (mouseY - posY) / delay
				TweenMax.set(follower, {
					css: {
						left: posX - (fWidth / 2),
						top: posY - (fWidth / 2)
					}
				});
			}
		});
		document.body.addEventListener("mousemove", function(e){
			mouseX = e.clientX
			mouseY = e.clientY
		});

		for (let i = 0; i < followItem.length; i++) {
			followItem[i].removeEventListener("mouseenter", {el: follower, handleEvent: mouseEnter})
			followItem[i].removeEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
			followItem[i].addEventListener("mouseenter",{el: follower, handleEvent: mouseEnter})
			followItem[i].addEventListener("mouseleave",{el: follower, handleEvent: mouseLeave})
		}
	}
};

function efectRipples(){
  $('.ripples').ripples(
    'drop',
    window.innerWidth,
    window.innerHeight/2,
    20,
    .06
    )
}

function mouseEnter(){
	this.el.classList.add('active');
}
function mouseLeave(){
	this.el.classList.remove('active');
}
